import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables. Make sure VITE_SUPABASE_URL and VITE_SUPABASE_ANON_KEY are set in your .env file.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
    storage: localStorage, // Use localStorage instead of sessionStorage for persistence
    storageKey: 'supabase.auth.token',
    flowType: 'pkce',
  },
  global: {
    fetch: async (...args) => {
      try {
        const response = await fetch(...args);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
      } catch (err) {
        // Add retry logic for network errors
        if (err instanceof TypeError && err.message === 'Failed to fetch') {
          // Retry up to 3 times with exponential backoff
          for (let i = 1; i <= 3; i++) {
            try {
              await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
              const retryResponse = await fetch(...args);
              if (retryResponse.ok) {
                return retryResponse;
              }
            } catch (retryErr) {
              console.error(`Retry ${i} failed:`, retryErr);
            }
          }
        }
        throw err;
      }
    }
  }
});

// Add connection health check with retry
export const checkConnection = async (retries = 3, delay = 2000): Promise<boolean> => {
  for (let i = 0; i < retries; i++) {
    try {
      const { data, error } = await supabase.from('profiles').select('count').limit(1);
      if (error) throw error;
      return true;
    } catch (error) {
      console.error(`Connection check attempt ${i + 1} failed:`, error);
      if (i < retries - 1) {
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
  }
  return false;
};

// Add retry logic for failed requests
export const retryRequest = async <T>(
  operation: () => Promise<T>,
  maxRetries = 3,
  delay = 1000
): Promise<T> => {
  let lastError: any;
  
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error;
      console.error(`Request attempt ${i + 1} failed:`, error);
      if (i < maxRetries - 1) {
        await new Promise(resolve => setTimeout(resolve, delay * (i + 1)));
      }
    }
  }
  
  throw lastError;
};

// Helper function to handle auth errors
export const handleAuthError = (error: any): string => {
  if (typeof error === 'object' && error !== null) {
    // Handle specific error codes
    if (error.code === 'email_exists') {
      return 'This email address is already in use. Please use a different email.';
    }
    if (error.message?.includes('Invalid Refresh Token')) {
      return 'Your session has expired. Please sign in again.';
    }
    if (error.message) {
      return error.message;
    }
  }
  return 'An unexpected error occurred. Please try again.';
};

// Function to track user session
export const trackSession = async () => {
  try {
    const userAgent = navigator.userAgent;
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    const { ip } = await ipResponse.json();

    await supabase.rpc('track_user_session', {
      p_user_agent: userAgent,
      p_ip_address: ip
    });
  } catch (error) {
    console.error('Error tracking session:', error);
  }
};

// Function to refresh session
export const refreshSession = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;

    if (session) {
      const { error: refreshError } = await supabase.auth.refreshSession();
      if (refreshError) throw refreshError;
    }
  } catch (error) {
    console.error('Error refreshing session:', error);
    // If refresh fails, sign out
    await supabase.auth.signOut();
  }
};