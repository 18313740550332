import React, { useState, useEffect } from 'react';
import { Bell, X, Check, CheckCheck, Settings, AlertCircle } from 'lucide-react';
import { useNotificationStore } from '../store/useNotificationStore';
import { useAuthStore } from '../store/useAuthStore';

interface NotificationCenterProps {
  className?: string;
}

export default function NotificationCenter({ className = '' }: NotificationCenterProps) {
  const { user, hasRole } = useAuthStore();
  const {
    notifications,
    preferences,
    unreadCount,
    loading,
    fetchNotifications,
    fetchPreferences,
    markAsRead,
    markAllAsRead,
    updatePreference,
    subscribeToNotifications,
    unsubscribeFromNotifications
  } = useNotificationStore();

  const [showNotifications, setShowNotifications] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);

  useEffect(() => {
    if (user) {
      fetchNotifications();
      fetchPreferences();
      subscribeToNotifications();

      // Request notification permission
      if ('Notification' in window && Notification.permission === 'default') {
        Notification.requestPermission();
      }

      return () => {
        unsubscribeFromNotifications();
      };
    }
  }, [user]);

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'comment_reply':
      case 'new_comment':
        return <Bell className="w-5 h-5" />;
      case 'content_approved':
        return <Check className="w-5 h-5" />;
      case 'content_rejected':
        return <X className="w-5 h-5" />;
      case 'reported_content':
      case 'user_flagged':
      case 'spam_detected':
        return <AlertCircle className="w-5 h-5" />;
      default:
        return <Bell className="w-5 h-5" />;
    }
  };

  const getNotificationColor = (priority: string) => {
    switch (priority) {
      case 'urgent':
        return 'text-red-400';
      case 'normal':
        return 'text-brand-yellow';
      case 'low':
        return 'text-gray-400';
      default:
        return 'text-gray-400';
    }
  };

  const handleNotificationClick = async (notification: any) => {
    if (!notification.read) {
      await markAsRead(notification.id);
    }

    // Handle navigation based on notification type and data
    if (notification.data?.url) {
      window.location.href = notification.data.url;
    }
  };

  return (
    <div className={`relative ${className}`}>
      <button
        onClick={() => setShowNotifications(!showNotifications)}
        className="relative p-2 text-gray-400 hover:text-white transition-colors"
      >
        <Bell className="w-5 h-5" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 w-5 h-5 bg-brand-yellow text-black text-xs rounded-full flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </button>

      {showNotifications && (
        <div className="absolute right-0 mt-2 w-96 max-h-[80vh] overflow-hidden rounded-lg shadow-lg z-50">
          <div className="glass-card">
            <div className="p-4 border-b border-white/10">
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold text-white">Notifications</h2>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setShowPreferences(true)}
                    className="p-2 text-gray-400 hover:text-white transition-colors"
                  >
                    <Settings className="w-4 h-4" />
                  </button>
                  {unreadCount > 0 && (
                    <button
                      onClick={() => markAllAsRead()}
                      className="p-2 text-gray-400 hover:text-white transition-colors"
                      title="Mark all as read"
                    >
                      <CheckCheck className="w-4 h-4" />
                    </button>
                  )}
                  <button
                    onClick={() => setShowNotifications(false)}
                    className="p-2 text-gray-400 hover:text-white transition-colors"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>

            <div className="overflow-y-auto max-h-[60vh]">
              {loading ? (
                <div className="p-4 text-center text-gray-400">Loading...</div>
              ) : notifications.length === 0 ? (
                <div className="p-8 text-center">
                  <Bell className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                  <p className="text-gray-400">No notifications yet</p>
                </div>
              ) : (
                <div className="divide-y divide-white/10">
                  {notifications.map((notification) => (
                    <button
                      key={notification.id}
                      onClick={() => handleNotificationClick(notification)}
                      className={`w-full p-4 text-left hover:bg-white/5 transition-colors ${
                        !notification.read ? 'bg-white/5' : ''
                      }`}
                    >
                      <div className="flex items-start gap-3">
                        <div
                          className={`p-2 rounded-lg ${getNotificationColor(
                            notification.priority
                          )}`}
                        >
                          {getNotificationIcon(notification.type)}
                        </div>
                        <div className="flex-1 min-w-0">
                          <h3 className="font-medium text-white">
                            {notification.title}
                          </h3>
                          <p className="text-sm text-gray-400 mt-1">
                            {notification.message}
                          </p>
                          <div className="flex items-center gap-2 mt-2 text-xs text-gray-500">
                            <span>
                              {new Date(notification.created_at).toLocaleString()}
                            </span>
                            {!notification.read && (
                              <span className="px-1.5 py-0.5 bg-brand-yellow/20 text-brand-yellow rounded-full">
                                New
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Notification Preferences Modal */}
      {showPreferences && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="glass-card w-full max-w-md">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold text-white">
                  Notification Settings
                </h2>
                <button
                  onClick={() => setShowPreferences(false)}
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-4">
                {preferences.map((pref) => (
                  <div
                    key={pref.type}
                    className="flex items-center justify-between p-3 bg-white/5 rounded-lg"
                  >
                    <div>
                      <h3 className="font-medium text-white">
                        {pref.type
                          .split('_')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      </h3>
                    </div>
                    <div className="flex items-center gap-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={pref.enabled}
                          onChange={(e) =>
                            updatePreference(
                              pref.type,
                              e.target.checked,
                              pref.email_enabled
                            )
                          }
                          className="w-4 h-4 rounded border-white/10 bg-white/5 text-brand-yellow focus:ring-brand-yellow"
                        />
                        <span className="text-sm text-gray-400">In-app</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={pref.email_enabled}
                          onChange={(e) =>
                            updatePreference(pref.type, pref.enabled, e.target.checked)
                          }
                          className="w-4 h-4 rounded border-white/10 bg-white/5 text-brand-yellow focus:ring-brand-yellow"
                        />
                        <span className="text-sm text-gray-400">Email</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}