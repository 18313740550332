import React from 'react';
import { Link } from 'react-router-dom';
import { BookOpen, Shield } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="backdrop-blur-xl bg-black/40 border-t border-white/10 py-8 px-6">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo and Description */}
          <div className="col-span-1 md:col-span-2">
            <Link to="/" className="block mb-4">
              <img
                src="https://quwgybiopiejoapbifjv.supabase.co/storage/v1/object/public/Branding//Logo%2011-01.png"
                alt="ComicVerse"
                className="h-8"
              />
            </Link>
            <p className="text-gray-400">
              {t('footer.tagline')}
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">{t('nav.quickLinks')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/trending" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.trending')}
                </Link>
              </li>
              <li>
                <Link to="/genres" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.genres')}
                </Link>
              </li>
              <li>
                <Link to="/calendar" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.calendar')}
                </Link>
              </li>
              <li>
                <Link to="/wiki" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.wiki')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-white font-semibold mb-4">{t('footer.legal.title')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/legal" className="text-gray-400 hover:text-white transition-colors">
                  {t('footer.legal.legalNotice')}
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-400 hover:text-white transition-colors">
                  {t('footer.legal.privacyPolicy')}
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-400 hover:text-white transition-colors">
                  {t('footer.legal.termsOfService')}
                </Link>
              </li>
              <li>
                <Link to="/privacy-notice" className="text-gray-400 hover:text-white transition-colors">
                  {t('footer.legal.privacyNotice')}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-white/10 flex flex-col md:flex-row items-center justify-between gap-4">
          <p className="text-gray-400 text-sm">
            {t('footer.copyright', { year: currentYear })}
          </p>
          <div className="flex items-center gap-4">
            <Link
              to="/privacy"
              className="text-gray-400 hover:text-white transition-colors flex items-center text-sm"
            >
              <Shield className="w-4 h-4 mr-1" />
              {t('footer.legal.privacyPolicy')}
            </Link>
            <Link
              to="/wiki"
              className="text-gray-400 hover:text-white transition-colors flex items-center text-sm"
            >
              <BookOpen className="w-4 h-4 mr-1" />
              {t('nav.wiki')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}