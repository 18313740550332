import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Heart, Download, BookOpen, Clock, Bookmark, TrendingUp, Grid3X3, Calendar, Shield, NotebookPen, Users, History, BookText, Palette } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useReadingProgressStore } from '../store/useReadingProgressStore';
import { useAuthStore } from '../store/useAuthStore';
import type { Database } from '../lib/supabase-types';

type Comic = Database['public']['Tables']['comics']['Row'];

interface ReadingProgress {
  comic: Comic;
  progress: {
    chapter_id: string;
    current_page: number;
    total_pages: number;
    progress_percentage: number;
    last_read_at: string;
  };
}

export default function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation('sidebar');
  const { hasRole } = useAuthStore();
  const { recentlyRead, fetchRecentlyRead } = useReadingProgressStore();
  const [latestProgress, setLatestProgress] = useState<ReadingProgress | null>(null);

  useEffect(() => {
    fetchRecentlyRead();
  }, [fetchRecentlyRead]);

  useEffect(() => {
    if (recentlyRead.length > 0) {
      setLatestProgress(recentlyRead[0]);
    }
  }, [recentlyRead]);

  const isActive = (path: string) => location.pathname === path;

  return (
    <aside className="w-52 min-h-screen backdrop-blur-md bg-white/5 border-r border-white/10">
      <div className="p-3">
        <Link to="/" className="block mb-6">
          <img
            src="https://quwgybiopiejoapbifjv.supabase.co/storage/v1/object/public/Branding//Logo%2011-01.png"
            alt="ComicVerse"
            className="h-8 mx-auto"
          />
        </Link>

        <nav className="space-y-6">
          <div className="space-y-1">
            <h3 className="text-xs uppercase text-gray-400 font-semibold px-2 mb-2">{t('menu.title')}</h3>
            <div className="space-y-1">
              <Link
                to="/"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <Home className={`w-4 h-4 mr-2 ${isActive('/') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('menu.home')}
              </Link>
              <Link
                to="/trending"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/trending') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <TrendingUp className={`w-4 h-4 mr-2 ${isActive('/trending') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('menu.trending')}
              </Link>
              <Link
                to="/genres"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/genres') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <Grid3X3 className={`w-4 h-4 mr-2 ${isActive('/genres') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('menu.genres')}
              </Link>
              <Link
                to="/calendar"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/calendar') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <Calendar className={`w-4 h-4 mr-2 ${isActive('/calendar') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('menu.calendar')}
              </Link>
              <Link
                to="/timeline"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/timeline') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <History className={`w-4 h-4 mr-2 ${isActive('/timeline') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('menu.timeline')}
              </Link>
              <Link
                to="/wiki"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/wiki') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <BookText className={`w-4 h-4 mr-2 ${isActive('/wiki') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('menu.wiki')}
              </Link>
              <Link
                to="/bookmarks"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/bookmarks') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <Bookmark className={`w-4 h-4 mr-2 ${isActive('/bookmarks') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('menu.bookmarks')}
              </Link>
            </div>
          </div>

          {/* Admin Links */}
          {(hasRole('author') || hasRole('moderator') || hasRole('admin')) && (
            <div className="space-y-1">
              <h3 className="text-xs uppercase text-gray-400 font-semibold px-2 mb-2">{t('admin.title')}</h3>
              <div className="space-y-1">
                {(hasRole('author') || hasRole('admin')) && (
                  <Link
                    to="/author"
                    className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                      isActive('/author') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                    }`}
                  >
                    <NotebookPen className={`w-4 h-4 mr-2 ${isActive('/author') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                    {t('admin.authorDashboard')}
                  </Link>
                )}
                {(hasRole('moderator') || hasRole('admin')) && (
                  <Link
                    to="/moderator"
                    className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                      isActive('/moderator') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                    }`}
                  >
                    <Shield className={`w-4 h-4 mr-2 ${isActive('/moderator') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                    {t('admin.moderatorDashboard')}
                  </Link>
                )}
                {hasRole('admin') && (
                  <>
                    <Link
                      to="/dashboard/users"
                      className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                        isActive('/dashboard/users') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                      }`}
                    >
                      <Users className={`w-4 h-4 mr-2 ${isActive('/dashboard/users') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                      {t('admin.userManagement')}
                    </Link>
                    <Link
                      to="/artists"
                      className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                        isActive('/artists') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                      }`}
                    >
                      <Palette className={`w-4 h-4 mr-2 ${isActive('/artists') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                      Artists
                    </Link>
                  </>
                )}
              </div>
            </div>
          )}

          <div className="space-y-1">
            <h3 className="text-xs uppercase text-gray-400 font-semibold px-2 mb-2">{t('library.title')}</h3>
            <div className="space-y-1">
              <Link
                to="/downloads"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/downloads') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <Download className={`w-4 h-4 mr-2 ${isActive('/downloads') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('library.downloads')}
              </Link>
              <Link
                to="/reading-list"
                className={`flex items-center px-2 py-1.5 text-gray-300 hover:text-white hover:bg-gradient-to-r hover:from-brand-yellow/10 hover:to-brand-red/10 rounded-lg group transition-all ${
                  isActive('/reading-list') ? 'text-white bg-gradient-to-r from-brand-yellow/10 to-brand-red/10' : ''
                }`}
              >
                <BookOpen className={`w-4 h-4 mr-2 ${isActive('/reading-list') ? 'text-brand-yellow' : 'group-hover:text-brand-yellow'} transition-colors`} />
                {t('library.readingList')}
              </Link>
            </div>
          </div>

          <div className="space-y-1">
            <h3 className="text-xs uppercase text-gray-400 font-semibold px-2 mb-2">{t('continueReading.title')}</h3>
            <div className="space-y-2">
              {latestProgress ? (
                <Link
                  to={`/comic/${latestProgress.comic.id}`}
                  className="block px-2"
                >
                  <div className="relative h-20 rounded-lg overflow-hidden group">
                    <img
                      src={latestProgress.comic.cover_image || 'https://images.unsplash.com/photo-1612036782180-6f0b6cd846fe?auto=format&fit=crop&q=80'}
                      alt={latestProgress.comic.title}
                      className="absolute inset-0 w-full h-full object-cover transition-transform group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent p-2 flex flex-col justify-end">
                      <h4 className="text-sm font-medium text-white line-clamp-1">
                        {latestProgress.comic.title}
                      </h4>
                      <div className="flex items-center justify-between text-xs text-gray-300 mt-1">
                        <div className="flex items-center">
                          <Clock className="w-3 h-3 mr-1 text-brand-yellow" />
                          {t('continueReading.lastRead', {
                            date: new Date(latestProgress.progress.last_read_at).toLocaleDateString()
                          })}
                        </div>
                        <span>{latestProgress.progress.progress_percentage}%</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <div className="px-2 py-1.5 text-gray-400 flex items-center">
                  <Clock className="w-4 h-4 mr-2 text-brand-yellow" />
                  <span className="text-sm">{t('continueReading.noActivity')}</span>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </aside>
  );
}