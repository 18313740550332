import React, { useEffect } from 'react';
import { initTurnstile } from '../lib/turnstile';

declare global {
  interface Window {
    turnstile: any;
  }
}

export default function TurnstileProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Initialize Turnstile on mount
    initTurnstile();
  }, []);

  return (
    <>
      {children}
      <div id="turnstile-container" style={{ display: 'none' }} />
    </>
  );
}