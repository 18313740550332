import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import { useAuthStore } from './useAuthStore';
import type { Database } from '../lib/supabase-types';

type NotificationType =
  | 'comment_reply'
  | 'content_approved'
  | 'content_rejected'
  | 'mention'
  | 'follow_update'
  | 'account_warning'
  | 'status_change'
  | 'new_comment'
  | 'reported_content'
  | 'user_flagged'
  | 'high_engagement'
  | 'spam_detected';

type NotificationPriority = 'urgent' | 'normal' | 'low';

interface Notification {
  id: string;
  type: NotificationType;
  priority: NotificationPriority;
  title: string;
  message: string;
  data: any;
  read: boolean;
  dismissed: boolean;
  created_at: string;
}

interface NotificationPreference {
  type: NotificationType;
  enabled: boolean;
  email_enabled: boolean;
}

interface NotificationState {
  notifications: Notification[];
  preferences: NotificationPreference[];
  unreadCount: number;
  loading: boolean;
  error: string | null;
  fetchNotifications: () => Promise<void>;
  fetchPreferences: () => Promise<void>;
  markAsRead: (notificationId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  updatePreference: (type: NotificationType, enabled: boolean, emailEnabled: boolean) => Promise<void>;
  subscribeToNotifications: () => void;
  unsubscribeFromNotifications: () => void;
}

export const useNotificationStore = create<NotificationState>((set, get) => {
  let notificationSubscription: ReturnType<typeof supabase.channel> | null = null;

  return {
    notifications: [],
    preferences: [],
    unreadCount: 0,
    loading: false,
    error: null,

    fetchNotifications: async () => {
      try {
        set({ loading: true, error: null });
        const { user } = useAuthStore.getState();
        if (!user) return;

        const { data: notifications, error } = await supabase
          .from('notifications')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (error) throw error;

        const { data: count } = await supabase.rpc('get_unread_notification_count');

        set({
          notifications: notifications || [],
          unreadCount: count || 0,
          loading: false
        });
      } catch (error) {
        console.error('Error fetching notifications:', error);
        set({ error: (error as Error).message, loading: false });
      }
    },

    fetchPreferences: async () => {
      try {
        const { user } = useAuthStore.getState();
        if (!user) return;

        const { data: preferences, error } = await supabase
          .from('notification_preferences')
          .select('*')
          .eq('user_id', user.id);

        if (error) throw error;
        set({ preferences: preferences || [] });
      } catch (error) {
        console.error('Error fetching notification preferences:', error);
      }
    },

    markAsRead: async (notificationId: string) => {
      try {
        const { error } = await supabase.rpc('mark_notification_read', {
          p_notification_id: notificationId
        });

        if (error) throw error;

        set(state => ({
          notifications: state.notifications.map(n =>
            n.id === notificationId ? { ...n, read: true } : n
          ),
          unreadCount: Math.max(0, state.unreadCount - 1)
        }));
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    },

    markAllAsRead: async () => {
      try {
        const { error } = await supabase.rpc('mark_all_notifications_read');

        if (error) throw error;

        set(state => ({
          notifications: state.notifications.map(n => ({ ...n, read: true })),
          unreadCount: 0
        }));
      } catch (error) {
        console.error('Error marking all notifications as read:', error);
      }
    },

    updatePreference: async (type: NotificationType, enabled: boolean, emailEnabled: boolean) => {
      try {
        const { user } = useAuthStore.getState();
        if (!user) return;

        const { error } = await supabase
          .from('notification_preferences')
          .upsert({
            user_id: user.id,
            type,
            enabled,
            email_enabled: emailEnabled
          });

        if (error) throw error;

        set(state => ({
          preferences: state.preferences.map(p =>
            p.type === type ? { ...p, enabled, email_enabled: emailEnabled } : p
          )
        }));
      } catch (error) {
        console.error('Error updating notification preference:', error);
      }
    },

    subscribeToNotifications: () => {
      const { user } = useAuthStore.getState();
      if (!user) return;

      notificationSubscription = supabase
        .channel('notifications')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'notifications',
            filter: `user_id=eq.${user.id}`
          },
          async (payload) => {
            const { new: notification } = payload;
            const { notifications, unreadCount } = get();

            // Update notifications list
            set({
              notifications: [notification, ...notifications],
              unreadCount: unreadCount + 1
            });

            // Show browser notification if supported
            if (
              'Notification' in window &&
              Notification.permission === 'granted' &&
              document.visibilityState !== 'visible'
            ) {
              new Notification(notification.title, {
                body: notification.message,
                icon: '/logo.png'
              });
            }
          }
        )
        .subscribe();
    },

    unsubscribeFromNotifications: () => {
      if (notificationSubscription) {
        notificationSubscription.unsubscribe();
        notificationSubscription = null;
      }
    }
  };
});