import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import { useAuthStore } from './useAuthStore';
import type { Database } from '../lib/supabase-types';

type Comic = Database['public']['Tables']['comics']['Row'];

interface ReadingProgress {
  comic_id: string;
  chapter_id: string;
  current_page: number;
  total_pages: number;
  progress_percentage: number;
  completed: boolean;
  last_read_at: string;
}

interface ReadingProgressState {
  currentProgress: ReadingProgress | null;
  recentlyRead: {
    comic: Comic;
    progress: ReadingProgress;
  }[];
  loading: boolean;
  error: string | null;
  updateProgress: (params: {
    comicId: string;
    chapterId: string;
    currentPage: number;
    totalPages: number;
  }) => Promise<void>;
  fetchProgress: (comicId: string, chapterId: string) => Promise<void>;
  fetchRecentlyRead: () => Promise<void>;
}

export const useReadingProgressStore = create<ReadingProgressState>((set, get) => ({
  currentProgress: null,
  recentlyRead: [],
  loading: false,
  error: null,

  updateProgress: async ({ comicId, chapterId, currentPage, totalPages }) => {
    try {
      set({ loading: true, error: null });
      const { user } = useAuthStore.getState();
      if (!user) throw new Error('User not authenticated');

      // First verify the chapter exists and get its pages
      const { data: chapter, error: chapterError } = await supabase
        .from('chapters')
        .select('pages')
        .eq('id', chapterId)
        .eq('comic_id', comicId)
        .single();

      if (chapterError || !chapter) {
        throw new Error('Chapter not found');
      }

      // Verify total pages matches chapter's actual pages
      const actualPages = chapter.pages as { url: string }[];
      if (actualPages.length !== totalPages) {
        throw new Error('Invalid total pages count');
      }

      // Verify current page is valid
      if (currentPage < 0 || currentPage > totalPages) {
        throw new Error('Invalid page number');
      }
      
      const { error } = await supabase.rpc('update_reading_progress', {
        p_user_id: user.id,
        p_comic_id: comicId,
        p_chapter_id: chapterId,
        p_current_page: currentPage,
        p_total_pages: totalPages
      });

      if (error) throw error;

      // Update local state
      set(state => ({
        currentProgress: {
          comic_id: comicId,
          chapter_id: chapterId,
          current_page: currentPage,
          total_pages: totalPages,
          progress_percentage: Math.min(Math.floor((currentPage * 100) / totalPages), 100),
          completed: currentPage === totalPages,
          last_read_at: new Date().toISOString()
        }
      }));

      // Refresh recently read list
      get().fetchRecentlyRead();
    } catch (error) {
      set({ error: (error as Error).message });
    } finally {
      set({ loading: false });
    }
  },

  fetchProgress: async (comicId: string, chapterId: string) => {
    try {
      set({ loading: true, error: null });
      const { user } = useAuthStore.getState();
      if (!user) throw new Error('User not authenticated');
      
      const { data, error } = await supabase
        .from('reading_progress')
        .select('*')
        .eq('comic_id', comicId)
        .eq('chapter_id', chapterId)
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      set({ currentProgress: data });
    } catch (error) {
      set({ error: (error as Error).message });
    } finally {
      set({ loading: false });
    }
  },

  fetchRecentlyRead: async () => {
    try {
      set({ loading: true, error: null });
      const { user } = useAuthStore.getState();
      if (!user) throw new Error('User not authenticated');
      
      const { data, error } = await supabase
        .from('reading_progress')
        .select(`
          *,
          comic:comics (*)
        `)
        .eq('user_id', user.id)
        .order('last_read_at', { ascending: false })
        .limit(10);

      if (error) throw error;

      set({
        recentlyRead: data.map(item => ({
          comic: item.comic as Comic,
          progress: {
            comic_id: item.comic_id,
            chapter_id: item.chapter_id,
            current_page: item.current_page,
            total_pages: item.total_pages,
            progress_percentage: item.progress_percentage,
            completed: item.completed,
            last_read_at: item.last_read_at
          }
        }))
      });
    } catch (error) {
      set({ error: (error as Error).message });
    } finally {
      set({ loading: false });
    }
  }
}));