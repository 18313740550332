import React from 'react';
import { Globe } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLanguageStore } from '../store/useLanguageStore';

const LANGUAGE_FLAGS = {
  en: "🇬🇧",
  fr: "🇫🇷",
  es: "🇪🇸"
} as const;

export default function LanguageSelector() {
  const { t } = useTranslation();
  const { currentLanguage, setLanguage } = useLanguageStore();

  return (
    <div className="relative group">
      <button
        className="p-2 text-gray-400 hover:text-white transition-colors rounded-lg flex items-center gap-2 bg-white/5 hover:bg-white/10"
        title={t('languages.' + currentLanguage)}
      >
        <Globe className="w-5 h-5" />
        <span className="flex items-center gap-1">
          <span className="text-lg leading-none">{LANGUAGE_FLAGS[currentLanguage]}</span>
          <span className="uppercase">{currentLanguage}</span>
        </span>
      </button>
      
      <div className="absolute right-0 mt-2 w-48 py-2 bg-black/80 backdrop-blur-xl border border-white/10 rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
        {(['en', 'fr', 'es'] as const).map((lang) => (
          <button
            key={lang}
            onClick={() => setLanguage(lang)}
            className={`w-full px-4 py-2 text-left transition-colors flex items-center gap-3 ${
              currentLanguage === lang
                ? 'text-brand-yellow bg-white/10'
                : 'text-gray-400 hover:text-white hover:bg-white/5'
            }`}
          >
            <span className="text-lg leading-none">{LANGUAGE_FLAGS[lang]}</span>
            <span>{t(`languages.${lang}`)}</span>
          </button>
        ))}
      </div>
    </div>
  );
}