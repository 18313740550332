import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

export default function Layout() {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="backdrop-blur-xl bg-black/30 flex-1">
        <Navbar />
        <div className="flex">
          <Sidebar />
          <main className="flex-1 p-6 pt-20">
            <div className="container-blur">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}