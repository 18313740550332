import { supabase } from './supabase';

// Cloudflare Turnstile configuration
const SITE_KEY = '0x4AAAAAAA-UCBG5n12iKsLT';

let turnstileScript: HTMLScriptElement | null = null;
let turnstileContainer: HTMLDivElement | null = null;
let scriptLoaded = false;

export function initTurnstile() {
  if (!turnstileScript) {
    turnstileScript = document.createElement('script');
    turnstileScript.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
    turnstileScript.async = true;
    turnstileScript.defer = true;
    
    // Add load event listener
    turnstileScript.addEventListener('load', () => {
      scriptLoaded = true;
    });

    document.head.appendChild(turnstileScript);

    // Create container if it doesn't exist
    if (!turnstileContainer) {
      turnstileContainer = document.createElement('div');
      turnstileContainer.id = 'turnstile-container';
      turnstileContainer.style.display = 'none';
      document.body.appendChild(turnstileContainer);
    }
  }
}

export async function verifyTurnstile(): Promise<boolean> {
  return new Promise((resolve) => {
    const maxAttempts = 50; // 5 seconds total
    let attempts = 0;

    const checkAndRender = () => {
      if (scriptLoaded && typeof window.turnstile !== 'undefined') {
        renderTurnstile(resolve);
        return true;
      }
      return false;
    };

    // Try immediately first
    if (checkAndRender()) return;

    // If not loaded, start polling
    const interval = setInterval(() => {
      attempts++;
      
      if (checkAndRender() || attempts >= maxAttempts) {
        clearInterval(interval);
        if (attempts >= maxAttempts) {
          console.warn('Turnstile failed to load, proceeding without verification');
          resolve(true);
        }
      }
    }, 100);
  });
}

function renderTurnstile(resolve: (value: boolean) => void) {
  try {
    if (!turnstileContainer) {
      turnstileContainer = document.createElement('div');
      turnstileContainer.id = 'turnstile-container';
      turnstileContainer.style.display = 'none';
      document.body.appendChild(turnstileContainer);
    }

    // Clear any existing widgets
    turnstileContainer.innerHTML = '';

    window.turnstile.render('#turnstile-container', {
      sitekey: SITE_KEY,
      action: 'verify',
      retry: 'auto',
      execution: 'render',
      refreshExpired: 'auto',
      callback: async (token: string) => {
        try {
          // Verify token with Supabase function
          const { data, error } = await supabase.rpc('verify_turnstile_token', {
            p_token: token,
            p_ip_address: await fetch('https://api.ipify.org?format=json')
              .then(r => r.json())
              .then(data => data.ip)
              .catch(() => null)
          });

          if (error) throw error;
          resolve(true);
        } catch (error) {
          console.warn('Turnstile verification failed:', error);
          resolve(true); // Resolve true to not block the user in case of failure
        }
      },
      'error-callback': () => {
        console.warn('Turnstile error, proceeding without verification');
        resolve(true);
      },
      'timeout-callback': () => {
        console.warn('Turnstile timeout, proceeding without verification');
        resolve(true);
      }
    });
  } catch (error) {
    console.warn('Error rendering Turnstile:', error);
    resolve(true);
  }
}