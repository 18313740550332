import React from 'react';
import { Shield, NotebookPen, BadgeCheck, VenetianMask, Sparkles } from 'lucide-react';

type UserRole = 'user' | 'author' | 'moderator' | 'admin';

interface RoleBadgeProps {
  role: UserRole;
  className?: string;
  badges?: { name: string; icon: string }[];
}

const ROLE_COLORS = {
  admin: 'bg-purple-500/20 text-purple-400',
  moderator: 'bg-blue-500/20 text-blue-400',
  author: 'bg-green-500/20 text-green-400',
  user: 'bg-gray-500/20 text-gray-400'
};

const ROLE_ICONS = {
  admin: <Shield className="w-4 h-4" />,
  moderator: <BadgeCheck className="w-4 h-4" />,
  author: <NotebookPen className="w-4 h-4" />,
  user: <VenetianMask className="w-4 h-4" />
};

const ROLE_TITLES = {
  admin: 'Administrator',
  moderator: 'Moderator',
  author: 'Author',
  user: 'User'
};

const BADGE_ICONS = {
  sparkles: <Sparkles className="w-4 h-4" />
};

export default function RoleBadge({ role, className = '', badges = [] }: RoleBadgeProps) {
  return (
    <span className="inline-flex items-center gap-1">
      <span
        title={ROLE_TITLES[role]}
        className={`inline-flex items-center justify-center p-1 rounded-full ${ROLE_COLORS[role]} hover:scale-110 transition-transform cursor-help ${className}`}
      >
        {ROLE_ICONS[role]}
      </span>
      {badges.map((badge, index) => (
        <span
          key={index}
          title={badge.name}
          className="inline-flex items-center justify-center p-1 rounded-full bg-yellow-500/20 text-yellow-400 hover:scale-110 transition-transform cursor-help"
        >
          {BADGE_ICONS[badge.icon as keyof typeof BADGE_ICONS]}
        </span>
      ))}
    </span>
  );
}