import { create } from 'zustand';
import { supabase, checkConnection, retryRequest, handleAuthError, trackSession, refreshSession } from '../lib/supabase';

type UserRole = 'user' | 'author' | 'moderator' | 'admin';

type Profile = {
  id: string;
  email: string;
  username: string;
  bio?: string;
  role: UserRole;
  created_at?: string;
  updated_at?: string;
};

interface Badge {
  name: string;
  icon: string;
  earned_at: string;
}

interface AuthState {
  user: Profile | null;
  loading: boolean;
  error: string | null;
  badges: Badge[];
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, username: string) => Promise<void>;
  signOut: () => Promise<void>;
  initialize: () => Promise<void>;
  hasRole: (role: UserRole) => boolean;
  silenceUser: (userId: string, duration: string) => Promise<void>;
  blacklistUser: (userId: string) => Promise<void>;
  fetchBadges: () => Promise<void>;
  updateProfile: (data: Partial<Profile>) => Promise<void>;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  loading: true,
  error: null,
  badges: [],

  signIn: async (email: string, password: string) => {
    try {
      set({ loading: true, error: null });

      // Check connection first
      const isConnected = await checkConnection();
      if (!isConnected) {
        throw new Error('Unable to connect to Supabase. Please check your internet connection and try again.');
      }

      // Sign in with email/password
      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      
      if (authError) throw authError;

      if (authData.user) {
        // Use retry logic for profile fetch with delay
        const profile = await retryRequest(async () => {
          const { data, error: profileError } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', authData.user!.id)
            .single();

          if (profileError) throw profileError;
          return data;
        }, 3, 1000); // 3 retries, 1 second delay

        set({ user: profile });

        // Track session after successful sign in
        await trackSession();
      }
    } catch (error) {
      const message = handleAuthError(error);
      set({ error: message, user: null });
      throw new Error(message);
    } finally {
      set({ loading: false });
    }
  },

  signUp: async (email: string, password: string, username: string) => {
    try {
      set({ loading: true, error: null });

      // Check connection first
      const isConnected = await checkConnection();
      if (!isConnected) {
        throw new Error('Unable to connect to Supabase. Please check your internet connection and try again.');
      }

      // Validate username
      if (!/^[a-zA-Z0-9_]{3,}$/.test(username)) {
        throw new Error('Username must be at least 3 characters long and can only contain letters, numbers, and underscores');
      }

      // Check if username is taken
      const { data: existingUser, error: checkError } = await supabase
        .from('profiles')
        .select('username')
        .eq('username', username)
        .maybeSingle();

      if (checkError) throw checkError;
      if (existingUser) throw new Error('Username is already taken');

      // Sign up user
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username,
          },
        },
      });

      if (signUpError) throw signUpError;

      if (authData.user) {
        // Wait for profile creation with retries
        const profile = await retryRequest(async () => {
          const { data, error: profileError } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', authData.user!.id)
            .single();

          if (profileError) throw profileError;
          return data;
        }, 5, 1000); // 5 retries, 1 second delay

        set({ user: profile });
      }
    } catch (error) {
      const message = handleAuthError(error);
      set({ error: message, user: null });
      throw new Error(message);
    } finally {
      set({ loading: false });
    }
  },

  signOut: async () => {
    try {
      set({ loading: true, error: null });
      
      // Sign out from Supabase
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear session storage
      sessionStorage.clear();
      
      set({ user: null });
    } catch (error) {
      set({ error: (error as Error).message });
    } finally {
      set({ loading: false });
    }
  },

  initialize: async () => {
    try {
      set({ loading: true, error: null });
      
      // Check connection first with retries
      const isConnected = await retryRequest(async () => {
        const connected = await checkConnection();
        if (!connected) {
          throw new Error('Unable to connect to Supabase');
        }
        return connected;
      }, 5, 2000); // 5 retries, 2 second delay

      if (!isConnected) {
        throw new Error('Unable to connect to Supabase. Please check your internet connection and try again.');
      }

      // Try to refresh session first
      await refreshSession();

      // Get current session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      // If no session, clear user state
      if (!session) {
        set({ user: null, loading: false });
        return;
      }

      // Get user profile
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (profileError) {
        // If profile fetch fails due to session issues, sign out
        if (profileError.message.includes('session_not_found')) {
          await supabase.auth.signOut();
          set({ user: null });
          return;
        }
        throw profileError;
      }

      set({ user: profile });

      // Set up auth state change listener
      const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
        if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
          set({ user: null });
        } else if (session?.user && event !== 'TOKEN_REFRESHED') {
          try {
            const { data: profile, error: profileError } = await supabase
              .from('profiles')
              .select('*')
              .eq('id', session.user.id)
              .single();

            if (profileError) throw profileError;
            set({ user: profile });
          } catch (error) {
            console.error('Error fetching profile:', error);
            set({ user: null });
          }
        }
      });

      // Clean up subscription on unmount
      return () => {
        subscription.unsubscribe();
      };
      
    } catch (error) {
      console.error('Auth initialization error:', error);
      set({ error: (error as Error).message, user: null });
    } finally {
      set({ loading: false });
    }
  },

  hasRole: (role: UserRole) => {
    const { user } = get();
    if (!user) return false;

    switch (role) {
      case 'admin':
        return user.role === 'admin';
      case 'moderator':
        return ['moderator', 'admin'].includes(user.role);
      case 'author':
        return ['author', 'moderator', 'admin'].includes(user.role);
      default:
        return true;
    }
  },

  silenceUser: async (userId: string, duration: string) => {
    try {
      set({ loading: true, error: null });
      const { error } = await supabase.rpc('silence_user', {
        target_user_id: userId,
        duration
      });
      if (error) throw error;
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  blacklistUser: async (userId: string) => {
    try {
      set({ loading: true, error: null });
      const { error } = await supabase.rpc('blacklist_user', {
        target_user_id: userId
      });
      if (error) throw error;
    } catch (error) {
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  fetchBadges: async () => {
    try {
      const { user } = get();
      if (!user) return;

      // Check and award new badges
      await supabase.rpc('check_and_award_badges', {
        p_user_id: user.id
      });

      // Fetch user's badges
      const { data, error } = await supabase
        .from('user_badges')
        .select(`
          badge:badge_id (
            name,
            icon
          ),
          earned_at
        `)
        .eq('user_id', user.id)
        .order('earned_at', { ascending: false });

      if (error) throw error;

      set({
        badges: data.map(b => ({
          name: b.badge.name,
          icon: b.badge.icon,
          earned_at: b.earned_at
        }))
      });
    } catch (error) {
      console.error('Error fetching badges:', error);
    }
  },

  updateProfile: async (data: Partial<Profile>) => {
    try {
      const { user } = get();
      if (!user) throw new Error('Not authenticated');

      const { error } = await supabase
        .from('profiles')
        .update(data)
        .eq('id', user.id);

      if (error) throw error;

      // Update local state
      set(state => ({
        user: state.user ? { ...state.user, ...data } : null
      }));
    } catch (error) {
      console.error('Error updating profile:', error);
      throw error;
    }
  }
}));