import React, { useEffect, useState } from 'react';
import { Cookie, X } from 'lucide-react';

interface CookieSettings {
  necessary: boolean;
  preferences: boolean;
  analytics: boolean;
  marketing: boolean;
}

export default function CookieConsent() {
  const [show, setShow] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState<CookieSettings>({
    necessary: true, // Always required
    preferences: true,
    analytics: true,
    marketing: false,
  });

  useEffect(() => {
    // Check if user has already made a choice
    const consent = localStorage.getItem('cookie-consent');
    if (!consent) {
      setShow(true);
    } else {
      setSettings(JSON.parse(consent));
    }
  }, []);

  const handleAcceptAll = () => {
    const allSettings: CookieSettings = {
      necessary: true,
      preferences: true,
      analytics: true,
      marketing: true,
    };
    saveSettings(allSettings);
  };

  const handleAcceptSelected = () => {
    saveSettings(settings);
  };

  const saveSettings = (selectedSettings: CookieSettings) => {
    localStorage.setItem('cookie-consent', JSON.stringify(selectedSettings));
    setSettings(selectedSettings);
    setShow(false);
    setShowSettings(false);
  };

  if (!show) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 p-4">
      <div className="glass-card max-w-4xl mx-auto">
        <div className="p-6">
          {showSettings ? (
            <>
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold text-white flex items-center">
                  <Cookie className="w-5 h-5 mr-2 text-brand-yellow" />
                  Cookie Settings
                </h2>
                <button
                  onClick={() => setShowSettings(false)}
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-4 mb-6">
                <div className="flex items-center justify-between p-4 bg-white/5 rounded-lg">
                  <div>
                    <h3 className="font-medium text-white">Necessary Cookies</h3>
                    <p className="text-sm text-gray-400">
                      Required for the website to function properly
                    </p>
                  </div>
                  <label className="relative inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={settings.necessary}
                      disabled
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-brand-yellow"></div>
                  </label>
                </div>

                <div className="flex items-center justify-between p-4 bg-white/5 rounded-lg">
                  <div>
                    <h3 className="font-medium text-white">Preferences</h3>
                    <p className="text-sm text-gray-400">
                      Remember your settings and preferences
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={settings.preferences}
                      onChange={(e) =>
                        setSettings((prev) => ({
                          ...prev,
                          preferences: e.target.checked,
                        }))
                      }
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-brand-yellow"></div>
                  </label>
                </div>

                <div className="flex items-center justify-between p-4 bg-white/5 rounded-lg">
                  <div>
                    <h3 className="font-medium text-white">Analytics</h3>
                    <p className="text-sm text-gray-400">
                      Help us improve by allowing analytics
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={settings.analytics}
                      onChange={(e) =>
                        setSettings((prev) => ({
                          ...prev,
                          analytics: e.target.checked,
                        }))
                      }
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-brand-yellow"></div>
                  </label>
                </div>

                <div className="flex items-center justify-between p-4 bg-white/5 rounded-lg">
                  <div>
                    <h3 className="font-medium text-white">Marketing</h3>
                    <p className="text-sm text-gray-400">
                      Allow personalized recommendations
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={settings.marketing}
                      onChange={(e) =>
                        setSettings((prev) => ({
                          ...prev,
                          marketing: e.target.checked,
                        }))
                      }
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-brand-yellow"></div>
                  </label>
                </div>
              </div>

              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowSettings(false)}
                  className="btn-secondary"
                >
                  Cancel
                </button>
                <button onClick={handleAcceptSelected} className="btn-primary">
                  Save Preferences
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center gap-2 mb-4">
                <Cookie className="w-6 h-6 text-brand-yellow" />
                <h2 className="text-xl font-semibold text-white">Cookie Consent</h2>
              </div>
              <p className="text-gray-300 mb-6">
                We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.
              </p>
              <div className="flex flex-wrap gap-2">
                <button onClick={handleAcceptAll} className="btn-primary">
                  Accept All
                </button>
                <button
                  onClick={() => setShowSettings(true)}
                  className="btn-secondary"
                >
                  Customize
                </button>
                <button
                  onClick={() => saveSettings({ ...settings, preferences: false, analytics: false, marketing: false })}
                  className="btn-secondary"
                >
                  Reject All
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}