import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import i18n from '../lib/i18n';

type Language = 'en' | 'fr' | 'es';

interface LanguageState {
  currentLanguage: Language;
  setLanguage: (language: Language) => void;
}

export const useLanguageStore = create<LanguageState>()(
  persist(
    (set) => ({
      currentLanguage: (i18n.language as Language) || 'en',
      setLanguage: (language) => {
        i18n.changeLanguage(language);
        set({ currentLanguage: language });
      },
    }),
    {
      name: 'language-store',
    }
  )
);