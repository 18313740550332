import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthStore } from './store/useAuthStore';
import Layout from './components/Layout';
import TurnstileProvider from './components/TurnstileProvider';
import CookieConsent from './components/CookieConsent';
import { setupCSP, setupSessionSecurity, setupSecurityHeaders } from './lib/security';

// Initialize security features
setupCSP();
setupSessionSecurity();
setupSecurityHeaders();

// Pages
const Home = React.lazy(() => import('./pages/Home'));
const ComicReader = React.lazy(() => import('./pages/ComicReader'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Auth = React.lazy(() => import('./pages/Auth'));
const AuthorDashboard = React.lazy(() => import('./pages/AuthorDashboard'));
const ModeratorDashboard = React.lazy(() => import('./pages/ModeratorDashboard'));
const Trending = React.lazy(() => import('./pages/Trending'));
const Genres = React.lazy(() => import('./pages/Genres'));
const EditComic = React.lazy(() => import('./pages/EditComic'));
const CreateComic = React.lazy(() => import('./pages/CreateComic'));
const ComicStats = React.lazy(() => import('./pages/ComicStats'));
const Bookmarks = React.lazy(() => import('./pages/Bookmarks'));
const PublicationCalendar = React.lazy(() => import('./pages/PublicationCalendar'));
const Downloads = React.lazy(() => import('./pages/Downloads'));
const ReadingList = React.lazy(() => import('./pages/ReadingList'));
const UserManagement = React.lazy(() => import('./pages/UserManagement'));
const Timeline = React.lazy(() => import('./pages/Timeline'));
const Wiki = React.lazy(() => import('./pages/Wiki'));
const WikiEntry = React.lazy(() => import('./pages/WikiEntry'));
const CreateWikiEntry = React.lazy(() => import('./pages/CreateWikiEntry'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const PrivacyNotice = React.lazy(() => import('./pages/PrivacyNotice'));
const Legal = React.lazy(() => import('./pages/Legal'));
const Terms = React.lazy(() => import('./pages/Terms'));
const Artists = React.lazy(() => import('./pages/Artists'));

// Protected Route Component
function ProtectedRoute({ children, role }: { children: React.ReactNode; role?: 'author' | 'moderator' | 'admin' }) {
  const { user, loading, hasRole } = useAuthStore();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  if (!user || (role && !hasRole(role))) {
    return <Navigate to="/auth" replace />;
  }

  return <>{children}</>;
}

function App() {
  const { initialize } = useAuthStore();

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <TurnstileProvider>
      <Router>
        <React.Suspense
          fallback={
            <div className="min-h-screen bg-gray-900 flex items-center justify-center">
              <div className="text-white">Loading...</div>
            </div>
          }
        >
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="comic/:id" element={<ComicReader />} />
              <Route path="comic/:id/edit" element={
                <ProtectedRoute>
                  <EditComic />
                </ProtectedRoute>
              } />
              <Route path="comic/create" element={
                <ProtectedRoute role="author">
                  <CreateComic />
                </ProtectedRoute>
              } />
              <Route path="comic/:id/stats" element={
                <ProtectedRoute role="author">
                  <ComicStats />
                </ProtectedRoute>
              } />
              <Route path="trending" element={<Trending />} />
              <Route path="genres" element={<Genres />} />
              <Route path="calendar" element={<PublicationCalendar />} />
              <Route path="timeline" element={<Timeline />} />
              <Route path="wiki" element={<Wiki />} />
              <Route path="wiki/create" element={
                <ProtectedRoute>
                  <CreateWikiEntry />
                </ProtectedRoute>
              } />
              <Route path="wiki/:id" element={<WikiEntry />} />
              <Route path="bookmarks" element={
                <ProtectedRoute>
                  <Bookmarks />
                </ProtectedRoute>
              } />
              <Route path="downloads" element={
                <ProtectedRoute>
                  <Downloads />
                </ProtectedRoute>
              } />
              <Route path="reading-list" element={
                <ProtectedRoute>
                  <ReadingList />
                </ProtectedRoute>
              } />
              <Route path="profile" element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              } />
              <Route path="privacy" element={<Privacy />} />
              <Route path="privacy-notice" element={<PrivacyNotice />} />
              <Route path="legal" element={<Legal />} />
              <Route path="terms" element={<Terms />} />
              <Route path="artists" element={<Artists />} />
              <Route
                path="dashboard/users"
                element={
                  <ProtectedRoute role="admin">
                    <UserManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="author"
                element={
                  <ProtectedRoute>
                    <AuthorDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="moderator"
                element={
                  <ProtectedRoute role="moderator">
                    <ModeratorDashboard />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
          <CookieConsent />
        </React.Suspense>
      </Router>
    </TurnstileProvider>
  );
}

export default App;