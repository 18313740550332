import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, Bell, User, LogOut, PenSquare } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../store/useAuthStore';
import { supabase } from '../lib/supabase';
import RoleBadge from './RoleBadge';
import NotificationCenter from './NotificationCenter';
import LanguageSelector from './LanguageSelector';

interface UserProfile {
  avatar_url: string | null;
  email: string;
  username: string;
  role: 'user' | 'author' | 'moderator' | 'admin';
}

export default function Navbar() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, signOut, initialize } = useAuthStore();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [fetchError, setFetchError] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const retryTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (user) {
      fetchProfile();
    } else {
      setProfile(null);
      setFetchError(false);
    }

    return () => {
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }
    };
  }, [user]);

  const fetchProfile = async () => {
    try {
      setFetchError(false);
      const { data, error } = await supabase
        .from('profiles')
        .select('avatar_url, email, username, role')
        .eq('id', user?.id)
        .single();

      if (error) {
        throw error;
      }

      setProfile(data);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setFetchError(true);

      // Retry after 5 seconds
      retryTimeoutRef.current = setTimeout(() => {
        if (user) {
          fetchProfile();
        }
      }, 5000);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    await signOut();
    navigate('/auth');
  };

  return (
    <div className="fixed top-4 z-50 w-[calc(100%-13rem)] ml-52 px-6">
      <div className="backdrop-blur-xl bg-black/40 border border-white/10 rounded-lg shadow-lg">
        <div className="px-4 py-2 flex items-center gap-4">
          <div className={`relative flex-1 transition-all ${isSearchFocused ? 'max-w-2xl' : 'max-w-3xl'}`}>
            <input
              type="text"
              placeholder={t('search.placeholder')}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
              className="w-full px-4 py-2 rounded-md bg-white/5 border border-white/10 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-brand-yellow"
            />
            <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>

          {user ? (
            <>
              <NotificationCenter />
              <LanguageSelector />
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="flex items-center gap-2 px-2 py-1 rounded-md bg-white/10 hover:bg-white/20 transition-colors"
                >
                  <div className="w-8 h-8 rounded-md bg-gradient-to-r from-brand-yellow to-brand-red flex items-center justify-center overflow-hidden">
                    {profile?.avatar_url ? (
                      <img
                        src={profile.avatar_url}
                        alt={profile.username || profile.email}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <User className="w-5 h-5 text-white" />
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-white">
                      {profile?.username || user.email?.split('@')[0] || 'Loading...'}
                    </span>
                    {profile?.role && <RoleBadge role={profile.role} />}
                  </div>
                </button>
                {showDropdown && (
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-black/80 backdrop-blur-md border border-white/10">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-white/5"
                      onClick={() => setShowDropdown(false)}
                    >
                      {t('auth.profile')}
                    </Link>
                    <button
                      onClick={() => {
                        setShowDropdown(false);
                        handleSignOut();
                      }}
                      className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-white/5"
                    >
                      <LogOut className="w-4 h-4 inline mr-2" />
                      {t('auth.signOut')}
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <LanguageSelector />
              <Link
                to="/auth"
                className="btn-primary"
              >
                {t('auth.signIn')}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}