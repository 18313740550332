import { supabase } from './supabase';

// Content Security Policy setup
export function setupCSP() {
  // Remove CSP meta tag approach since it should be handled by the server
  // Instead, log a reminder that CSP should be configured on the server
  console.info('CSP should be configured via server HTTP headers for proper security');
}

// Enhanced CORS Headers
export function setupSecurityHeaders() {
  // Remove meta tag approach for security headers
  // These should be set by the server
  console.info('Security headers should be configured via server HTTP headers');
}

// Session Security
export function setupSessionSecurity() {
  const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes
  let lastActivity = Date.now();

  // Session timeout
  const checkSessionTimeout = () => {
    const now = Date.now();
    if (now - lastActivity > SESSION_TIMEOUT) {
      supabase.auth.signOut();
      window.location.href = '/auth';
    }
  };

  // Activity tracking
  const updateActivity = () => {
    lastActivity = Date.now();
  };

  // Set up event listeners
  window.addEventListener('mousemove', updateActivity);
  window.addEventListener('keydown', updateActivity);
  window.addEventListener('click', updateActivity);
  window.addEventListener('scroll', updateActivity);

  // Check session every minute
  setInterval(checkSessionTimeout, 60 * 1000);

  // Session storage listener
  window.addEventListener('storage', (e) => {
    if (e.key?.includes('supabase.auth')) {
      window.location.reload();
    }
  });

  // Device tracking
  trackDevice();
}

// Device Tracking
async function trackDevice() {
  try {
    const deviceInfo = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timestamp: new Date().toISOString()
    };

    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      await supabase.rpc('update_device_info', {
        p_device_info: deviceInfo
      });
    }
  } catch (error) {
    console.error('Error tracking device:', error);
  }
}

// Input Sanitization
export function sanitizeInput(input: string): string {
  return input
    .replace(/[<>]/g, '')
    .replace(/javascript:/gi, '')
    .replace(/data:/gi, '')
    .replace(/on\w+=/gi, '')
    .trim();
}

// Password Strength Check
export function checkPasswordStrength(password: string): {
  score: number;
  feedback: string[];
} {
  const feedback = [];
  let score = 0;

  if (password.length >= 12) score += 2;
  else if (password.length >= 8) score += 1;
  else feedback.push('Password should be at least 8 characters long');

  if (/[A-Z]/.test(password)) score += 1;
  else feedback.push('Include at least one uppercase letter');

  if (/[a-z]/.test(password)) score += 1;
  else feedback.push('Include at least one lowercase letter');

  if (/[0-9]/.test(password)) score += 1;
  else feedback.push('Include at least one number');

  if (/[^A-Za-z0-9]/.test(password)) score += 1;
  else feedback.push('Include at least one special character');

  if (/(.)\1{2,}/.test(password)) {
    score -= 1;
    feedback.push('Avoid repeated characters');
  }

  if (/^(?:123|abc|qwerty|password|admin)/i.test(password)) {
    score -= 1;
    feedback.push('Avoid common password patterns');
  }

  return {
    score: Math.max(0, Math.min(5, score)),
    feedback
  };
}

// Token Validation
export function validateToken(token: string): boolean {
  if (!/^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]*$/.test(token)) {
    return false;
  }

  try {
    const [, payload] = token.split('.');
    const decodedPayload = JSON.parse(atob(payload));
    const expirationTime = decodedPayload.exp * 1000;

    return Date.now() < expirationTime;
  } catch {
    return false;
  }
}

// Rate Limiting
const RATE_LIMITS = {
  'auth': { max: 5, window: 60 },
  'comment': { max: 10, window: 60 },
  'upload': { max: 20, window: 300 },
  '2fa': { max: 3, window: 300 },
} as const;

export async function checkRateLimit(endpoint: keyof typeof RATE_LIMITS): Promise<boolean> {
  try {
    // Get IP address safely
    let ipAddress = '';
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      ipAddress = data.ip;
    } catch (error) {
      console.error('Failed to get IP address:', error);
      ipAddress = 'unknown';
    }

    const { data, error } = await supabase.rpc('check_rate_limit', {
      p_ip_address: ipAddress,
      p_endpoint: endpoint,
      p_max_requests: RATE_LIMITS[endpoint].max,
      p_window_seconds: RATE_LIMITS[endpoint].window
    });

    if (error) throw error;
    if (!data) throw new Error('Invalid rate limit response');

    if (!data.allowed) {
      const resetTime = new Date(data.reset_at);
      const waitSeconds = Math.ceil((resetTime.getTime() - Date.now()) / 1000);
      throw new Error(`Rate limit exceeded. Please try again in ${waitSeconds} seconds.`);
    }

    return data.allowed;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Rate limit check failed');
  }
}